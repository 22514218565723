//@ts-ignore
import { addPropertyControls, ControlType } from "framer"
import { useEffect, useState, cloneElement, useMemo } from "react"
import debounce from "lodash.debounce"

const HEADING_TO_DISPLAY = ["h2"]

export default function TableOfContents(props) {
    const [activeHeading, setActiveHeading] = useState("")

    // Error check for missing description or children
    const result = useMemo(() => {
        // Ensure `headings` is an array, default to empty array if not
        const headings = Array.isArray(props?.description?.props?.children)
            ? props.description.props.children
            : []

        if (headings.length === 0) {
            console.error("No valid headings found.")
        }

        return headings
            .filter(
                (item) =>
                    item &&
                    HEADING_TO_DISPLAY.includes(item.type) &&
                    item?.props
            )
            .map((item, index) => {
                const children = item.props.children
                const { formattedId, formattedHeading } = formatHeading(
                    children,
                    index
                )
                return {
                    id: formattedId,
                    heading: formattedHeading,
                    type: item.type,
                    originalIndex: index,
                }
            })
            .sort((a, b) => a.originalIndex - b.originalIndex) // Sort here to optimize rendering
    }, [props.description])

    useEffect(() => {
        if (typeof IntersectionObserver === "undefined") {
            console.error(
                "IntersectionObserver is not supported in this environment."
            )
            return
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const id = entry.target.id
                    setActiveHeading(id)
                }
            })
        })

        const updateObservers = debounce(() => {
            const headings = document.querySelectorAll(
                '[data-framer-name="Content"] > [data-framer-component-type="RichTextContainer"] > h2'
            )
            observer.disconnect()
            headings.forEach((heading) => observer.observe(heading))
        }, 200)

        const mutationObserver = new MutationObserver(updateObservers)
        mutationObserver.observe(document.body, {
            childList: true,
            subtree: true,
        })

        updateObservers()

        return () => {
            observer.disconnect()
            mutationObserver.disconnect()
        }
    }, [])

    return (
        <ul className="framer-text">
            {result.length > 0 ? (
                result.map(({ id, heading, originalIndex }) => {
                    const child =
                        props?.heading?.[0]?.props?.children?.props?.children ??
                        props.heading?.[0] ??
                        null

                    if (!child) {
                        console.error("No valid heading component found.")
                        return null
                    }

                    // Use a combination of id and index to ensure a unique key
                    const uniqueKey = id
                        ? `${id}-${originalIndex}`
                        : `${originalIndex}`

                    const ComponentCopy = cloneElement(child, {
                        variant: activeHeading === id ? "Active" : "Inactive",
                        title: heading,
                        link: `../${props.path}/${props.slug}${id ? "#" + id : ""}`,
                        style: { width: "100%" },
                    })
                    return <li key={uniqueKey}>{ComponentCopy}</li>
                })
            ) : (
                <li>No headings available.</li>
            )}
        </ul>
    )
}

addPropertyControls(TableOfContents, {
    description: {
        title: "Content",
        //@ts-ignore
        type: ControlType.RichText,
    },
    path: {
        title: "DefaultPath",
        defaultValue: "blog",
        type: ControlType.String,
    },
    slug: {
        title: "Slug",
        //@ts-ignore
        type: ControlType.String,
    },
    heading: {
        type: ControlType.ComponentInstance,
    },
})

const formatHeading = (children, index) => {
    let formattedHeading = ""
    let formattedId = ""

    // Check if children is missing or is an invalid type
    if (!children) {
        console.error(`Heading at index ${index} is missing content.`)
        return { formattedId, formattedHeading }
    }

    // If children is an array, join the text content of its elements
    if (Array.isArray(children)) {
        formattedHeading = children
            .map((child) =>
                typeof child === "string" ? child : child?.props?.children || ""
            )
            .join(" ") // Join array elements into a single string
            .trim()
    } else if (typeof children === "string") {
        formattedHeading = children.trim()
    }

    // Generate a formatted ID if there is valid text content
    if (formattedHeading) {
        formattedId = formattedHeading
            .toLowerCase()
            .replace(/[^a-zäöü]+/g, "-")
            .replace(/^-+|-+$/g, "")
    }

    return { formattedId, formattedHeading }
}
